import React, { useEffect, useState } from 'react';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import restHandler from './apiClient';
import './StatusUxSupport.css';
import CustomAlert from './components/CustomAlert';
import { showDateOnly, showYesNo, showPercent } from './utils/helpers';
import ConfirmDialog from './components/ConfirmDialog';
import { notice_error } from './utils/toast_helpers';
import Loading from './components/Loading';

const confirmLink = (event) => {
    const shouldNavigate = window.confirm("The link you clicked provides additional resources for fixing vulnerabilities and requires leaving this site. Do you want to continue?");
    if (!shouldNavigate) {
        event.preventDefault();
    }
};

const IntelCard = ({ title, value, urgent = false }) => (
        <Card className="card card-body bg-light bg-gradient">
            <div className="d-flex mb-4 align-items-center">
                <div className="flex-shrink-0">
                    <i className="bx bx-calendar fs-20 mr-10"></i>
                </div>
                <div className="flex-grow-1 ms-2">
                    <h5 className="card-title mb-1 fs-20">{title}</h5>
                </div>
            </div>
            <h6 className={`mb-1 fs-20 centered-content ${urgent?'fw-bold text-danger':''}`}>{value}</h6>
        </Card>
)

const NvdInfo = ({ title, cve }) => {

    return (
            <Card className="card card-body bg-light bg-gradient">
                <div className="d-flex mb-4 align-items-center">
                    <div className="flex-shrink-0">
                        <i className="bx bx-info-square fs-20 mr-10"></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                        <h5 className="card-title mb-1 fs-20">{title}</h5>
                    </div>
                </div>
                <div className="xcontainer xmt-4">
                    <div className="d-flex flex-wrap" role="group" aria-label="Button group with links">
                        <a key={cve} onClick={confirmLink} href={`https://nvd.nist.gov/vuln/detail/${cve}`}
                            target='_blank' rel='noopener noreferrer' className="xlink btn btn-primary m-1">{cve}</a>
                    </div>
                </div>
            </Card>);
}

const MsKbArticles = ({ title, articles }) => {
    return (
        <div className="container">
            <h5>{title}</h5>
            <div className="d-flex flex-wrap gap-2">
                {Object.keys(articles).map((key) => (
                    <a
                        key={key}
                        onClick={confirmLink}
                        href={`https://support.microsoft.com/en-us/Search/results?query=${key}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className="btn btn-primary"
                        style={{ minWidth: "120px" }}  // Adjust minWidth if you need a wider button
                    >
                        {key}
                    </a>
                ))}
            </div>
        </div>
    );
};

const VulnerabilityModal = ({ isOpen, onClose, cve }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const result = await restHandler('GET', '/api/svintel/' + cve);
                setData(result);
            } catch (error) {
                setError(error.detail || error);
                notice_error(error.detail || error);
            }
        };

        fetchData();
    }, [cve]);


    if (!isOpen) return null; // Don't render if the modal is closed

    if (error) return <CustomAlert type='error' message={error} />
    if (!data) { return <Loading /> }

    const orpheus = data.orpheus;
    const alert_class = orpheus.exploited_in_wild ? 'alert-danger' : 'alert-info';

    return (
        <Modal size="xl" show={isOpen} onHide={onClose} className="vuln-modal-content" centered>
            <Modal.Header closeButton>
                <Modal.Title><i className="ri-bug-2-fill fs-30 mr-10"></i>
                    {data.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="vuln-modal-body">
                <Row>
                    <div className="alert alert-dark alert-outline" role="alert">
                        <h3>Summary</h3>
                        <p className="fs-18">{data.description}</p>
                    </div>
                </Row>
                <Row>
                    <div className={`alert $(alert_class) alert-danger alert-dismissible alert-label-icon label-arrow fade show vuln-horizontal-layout`} role="alert">
                        <i className="ri-alert-line label-icon fs-1"></i>
                        <span className="fs-3"><strong>Exploitation Status</strong>
                            &nbsp;-&nbsp;
                            {data.orpheus.exploitation_status}
                        </span>
                    </div>
                </Row>
                <Row>
                    <div className="alert alert-dark alert-outline fs-18 vuln-horizontal-layout vuln-cards-container" id="two-col">
                        <IntelCard title="Last Updated on" value={showDateOnly(orpheus.last_updated)} />
                        <IntelCard title="Risk Score" value={orpheus.sv_score} urgent={orpheus.sv_score>=650} />
                        <IntelCard title="Ransomware" value={showYesNo(orpheus.ransomware_related)} urgent={orpheus.ransomware_related} />
                        <IntelCard title="Exploit in Wild" value={showYesNo(orpheus.exploited_in_wild)} urgent={orpheus.exploited_in_wild} />
                        <IntelCard title="Likelihood of Exploit" value={showPercent(orpheus.exploit_probablity)} urgent={orpheus.exploit_probablity > 50} />
                        <NvdInfo title="NVD Information" cve={data.id} />
                    </div>
                </Row>
                {data.ms_kb &&(
                <Row className="alert alert-dark alert-outline fs-18">
                    <MsKbArticles title="Microsoft KB Articles" articles={data.ms_kb.articles} />
                </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VulnerabilityModal;